.masonry-container {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap:0;
    -moz-column-gap:0;
    column-gap:0;
    row-gap:0;
    margin:0; padding:0;
    background:#fff;
}

.masonry-item {
  width: 100%;
  margin:0; padding:0;
  column-gap:0;
    row-gap:0;
}

.masonry-item img {
    display:block;
    width: 100%; height:100%;
    margin:0; padding:0;
    transition: transform .2s; /* Animation */
  }
  .masonry-item img:hover {
    transform: scale(1.5);
    border:2px solid #fff;
    box-shadow: 2px 2px 5px rgba(0,0,0.1);
  }  

@media only screen and (min-width: 776px) {
    .masonry-container {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}

@media only screen and (min-width: 992px) {
    .masonry-container {
        -webkit-column-count: 6;
        -moz-column-count: 6;
        column-count: 6;
    }
  }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .masonry-container {
      -webkit-column-count: 8;
      -moz-column-count: 8;
      column-count: 8;
      margin:0;
  }
}