.contact-container {
    padding:40px;
}
.contact-container * {
    box-sizing: border-box;
}
.social {
    text-align:center;
    width:95px;
    height:95px;
    background:rgb(255,255,255);
    border:1px solid rgb(204,204,204);
    box-shadow:0 2px 4px rgba(0,0,0,0.15), inset 0 0 50px rgba(0,0,0,0.1);
    border-radius:95px;
    margin:0 10px 10px 0;
    padding:19px;
    color:gray;
    display:inline-block;
    overflow:hidden;
    font-size:14px;
}
.google-pluse:hover{background:#DD4B39;color:#FFF;}
.facebook:hover{background:#3b5998;color:#FFF;}
.twitter:hover{background:#00acee;color:#FFF;}
.pinterest:hover{background:#c8232c;color:#FFF;}
.linkedin:hover{background:#0e76a8;color:#FFF;}
.skype:hover{background:#00aff0;color:#FFF;}
.youtube:hover{background:#c4302b;color:#FFF;}
.tumblr:hover{background:#34526f;color:#FFF;}	


.contact-container label {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 0.5em;
    text-transform: capitalize;
}

.contact-container input {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    padding-left: 15px;
    border: 1px solid #c3c3c3;
    outline: none;
    color: #7b7b7b;
    font-family: Poppins;
    font-size: 14px;
}

.contact-container textarea {
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    border-radius: 4px;
    padding: 15px;
    border: 1px solid #c3c3c3;
    outline: none;
    color: #7b7b7b;
    font-family: Poppins;
    font-size: 14px;
}

.contact-container input:focus, .contact-container textarea:focus, .contact-container select:focus {
    border: 1px solid #aaa;
    background-color:#efefef;
  }

.btn {
    margin-top: 40px;
    width: 160px;
    height: 50px;
    border-radius: 6px;
    background: #6079ff;
    border: none;
    cursor: pointer;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}