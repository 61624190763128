.breadcrums{
    align-items: center;
    gap: 8px;
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 500;
}

@media(max-width:1024px){
    .breadcrums{
        margin: 0  8%;
    }
}
@media(max-width:800px){
    .breadcrums{
        margin: 0 5%;
    }
}
