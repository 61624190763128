.hero{
    min-height: 80vh;
    /* background:  linear-gradient(180deg, #fde1ff, #e1ffea22 60%); */
    /* background:url('images/Hero02.jpg') no-repeat top center; */

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 9%;
    margin-top:90px;
}
.hero-left{
    line-height: 1.1;
    max-width: 25vw;
}
.hero-left h2{
    color: rgba(255,255,255,.85);
    font-size: 18px;
}

.hero-left p{
    color: rgba(255,255,255,.85);
    font-size: 80px;   
    font-weight: 700;
    text-shadow: 0 1px 0 #ccc,
    0 6px 1px rgba(0,0,0,.1),
    0 0 5px rgba(0,0,0,.1),
    0 1px 3px rgba(0,0,0,.3),
    0 3px 5px rgba(0,0,0,.2),
    0 5px 10px rgba(0,0,0,.25),
    0 10px 10px rgba(0,0,0,.2),
    0 20px 20px rgba(0,0,0,.15);
}
.hero-hand-icon{
    display: flex;
    align-items: center;
    gap: 20px;
}
.hero-hand-icon img{
    width: 70px;
}
.hero-latest-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 250px;
    height: 60px;
    border-radius: 75px;
    margin-top: 20px;
    background: rgba(138, 0, 0,.9);
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    cursor: pointer;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);
    transition: background-color 1s;
}
.hero-latest-btn:hover{
    font-weight: 550;
    background: rgb(110, 0, 0);
    border:1px solid #4e0000;
    background: linear-gradient(90deg, darkred, transparent) rgba(110, 0, 0, .9);
}
.hero-latest-btn img{
    width: 18px;
}

.hero-right img{
    width: 450px;
}

@media(max-width:1200px){
    
    .hero{
        padding: 10px 6%;
    }
    .hero-left p{
        font-size: 70px;
    }
    .hero-right img{
        width: 400px;
    }
   
}
@media(max-width:900px){
   
    .hero-left p{
        font-size: 45px;
    }
    .hero-right img{
        width: 350px;
    }
   
}
@media(max-width:700px){
   .hero{
    padding: 0 10%;
   }
    .hero-left p{
        font-size: 55px;
    }
    .hero-right{
        display: none;
    }
    .hero-left{
        line-height: 1.2;
    }
    .hero-left h2{
        margin-bottom: 20px;
    }
  
    .hero-latest-btn{
        margin-top: 30px;
    }
}
