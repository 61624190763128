.itemPatch{
    width: 240px;
    height:300px;
    position:relative;
}
.itemPatch img{
    max-width: 240px;
    max-height:240px;
    width:100%; height:100%;
    object-fit:contain;
}

.itemPatch:hover img{
    border:0px solid rgba(255,255,255,.95);
}
.itemPatch p{
    margin: 6px 0px 3px 0;
}
body .shopcategory-products {
    gap:2em;
}
.item-prices{
    display: flex;
    gap: 15px;
    width:100%;
    align-items: center;
    justify-content: center;
}

#home_leather_patches .itemPatch .item-prices{
    display: flex;
    gap: 15px;
    display:none;
    position:relative;
    top:0;
}
.item-price-old {
    display:none;
}
.item-price-new{
    color: rgb(255 255 255 / 68%);
    font-size: 15px;
    font-weight: 500;
}
.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.itemPatch:hover{
    font-weight:bold;
    z-index:10;
    transform: scale(1.15);
    transition: 0.4s;
}



 .ribbon {
    position: absolute;
    right: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px; 
    text-align: right;
 }
 .ribbon span {
    font-size: 10px;
    color: #fff; 
    text-transform: uppercase; 
    text-align: center;
    font-weight: bold; line-height: 20px;
    letter-spacing: .1em;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); /* Needed for Safari */
    width: 100px; display: block;
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
 }

 .ribbon span::before {
    content: '';
    position: absolute; 
    left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
 }
 .ribbon span::after {
    content: '';
    position: absolute; 
    right: 0%; top: 100%;
    z-index: -1;
    border-right: 3px solid #79A70A;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
 }

.red span {background: linear-gradient(darkred 0%,darkred 100%);}
.red span::before {border-left-color: #8F0808; border-top-color: #580606;}
.red span::after {border-right-color: #8F0808; border-top-color: #580606;}

.blue span {background: linear-gradient(#2989d8 0%, #1e5799 100%);}
.blue span::before {border-left-color: #1e5799; border-top-color: #1e5799;}
.blue span::after {border-right-color: #1e5799; border-top-color: #1e5799;}





@media(max-width:800px){
    .item{
        width: 35%;
    }
    .item-price-old{
        font-size: 12px;
    }
}
@media(max-width:500px){
    .item p{
        font-size: 14px;
    }
    .item-price-new{
        font-size: 14px;
    }
}