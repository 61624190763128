.thubnail-container { display:flex; align-items:center; justify-content:center; width:100%; }
.img-slider-img { transition: all 400ms ease-in-out }
.img-thumbnail  {
    transition: scale 100ms ease-in-out;
    border:1px solid rgba(255,255,255,0);
}
.img-thumbnail:hover {
    scale:1.05;
    border:1px solid rgba(255,255,255,.75);
} 
.btn-prev, .btn-next { 
    display: flex; opacity:0;
    align-items: center; justify-content: center; height:100%; width:100px; 
    transition: opacity 400ms linear;
}
.btn-prev:hover {
    opacity:.75;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0,0,0,0) 100%);
}

.btn-next:hover {
    opacity:.75;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0,0,0,0) 100%);
}
