.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
    align-content: stretch;
    position:relative;
  }
  
  .flex-items:nth-child(1) {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: center;
    order: 0;
padding-bottom:40px;
  }
  
  .flex-items:nth-child(2) {
    display: block;
    flex-grow: 0;
    flex-shrink: 2;
    flex-basis: auto;
    align-self: flex-end;
    order: 0;
  }


.flex-items:nth-child(1) h1{
    color: #fff;


}
.flex-items:nth-child(1) p{
    color: silver;
}
.flex-items:nth-child(1) button{

    border-radius: 35px;
    background: rgba(138, 0, 0,.9);

    border: none;
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    transition: background-color 1s;
}

.flex-items:nth-child(1) button:hover{
    font-weight: 550;
    background: rgb(110, 0, 0);
    border:1px solid #4e0000;
    text-shadow: none;
}


@media(max-width:1280px){
    .flex-container{  width:80%; margin:auto; }
}
@media(max-width:1024px){
    .flex-container{ width:80%; margin:auto; }
    .flex-items:nth-child(1) { padding-bottom:30px;}
}
@media(max-width:800px){
    .flex-container{  width:90%; margin:auto; }
    .flex-items:nth-child(1) { padding-bottom:30px;}
}
@media(max-width:640px){
    .flex-container { flex-direction: column; }
    .flex-container{  width:90%; margin:auto; }
    .flex-items:nth-child(1) { padding-bottom:20px;  text-align:center; }
    .flex-items:nth-child(1) button{  position:absolute; bottom:24px; z-index:4;  align-items: center;  justify-content: center; text-align: center; margin-left: auto;
        margin-right: auto; 
        left: 0; 
        right: 0; 
        max-width:200px;
    }
}
