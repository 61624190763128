
.ribbon {
    position: absolute;
    right: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px; 
    text-align: right;
 }
 .ribbon span {
    font-size: 10px;
    color: #fff; 
    text-transform: uppercase; 
    text-align: center;
    font-weight: bold; line-height: 20px;
    letter-spacing: .1em;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); /* Needed for Safari */
    width: 100px; display: block;
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
 }

 .ribbon span::before {
    content: '';
    position: absolute; 
    left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
 }
 .ribbon span::after {
    content: '';
    position: absolute; 
    right: 0%; top: 100%;
    z-index: -1;
    border-right: 3px solid #79A70A;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
 }

.red span {background: linear-gradient(darkred 0%,darkred 100%);}
.red span::before {border-left-color: #8F0808; border-top-color: #580606;}
.red span::after {border-right-color: #8F0808; border-top-color: #580606;}


.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: space-between;
  }
  
  .flex-items:nth-child(1) {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
  }
  
  .flex-items:nth-child(2) {
    display: block;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
  }

/*
.productdisplay{
    display: flex;
    padding: 0 10% 0 10%;
    gap: 40px;
    justify-content: space-between;
}
.productdisplay-left{
    display: flex;
    gap: 18px;
}
.productdisplay-img-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.productdisplay-img-list img{
    min-width: 160px;
    max-width: 160px;
}
.productdisplay-main-img{
    min-width: 320px;
    max-width: 640px;
    object-fit:contain;
}
.productdisplay-right{
    display: flex;
    flex-direction: column;
}

.productdisplay-right-stars{
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
    color: rgba(255,255,255,.85);
    font-size: 14px;
}
.productdisplay-right-prices{
    display: flex;
    margin: 25px 0px;
    gap: 20px;
    font-size: 22px;
    font-weight: 700;
}
.productdisplay-right-price-old{
    color: #818181;
    text-decoration: line-through;
}
.productdisplay-right-price-new{
    color: greenyellow;
}
.productdisplay-right-size h1{

    color: rgba(255,255,255,.75);
    font-size: 18px;
    font-weight: 600;
}
.productdisplay-right-sizes{
    display: flex;
    margin: 20px 0px;
    gap: 15px;
}
.productdisplay-right-sizes div{
    text-align:center;
    padding: 16px 20px;
    background: rgba(255,255,255,.1);
    border: 1px solid rgba(255,255,255,.2);
    border-radius: 3px;
    cursor: pointer;
}
.productdisplay-right-sizes div.one-size{
    min-width:110px;
}
.productdisplay-right button{
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: darkred;
    margin-bottom: 25px;
    border: none;
    outline: none;
    cursor: pointer;
}
.productdisplay-right-category{
    margin-top: 10px;
}
.productdisplay-right-category span{
    font-weight: 600;
}

.fa-star { color:rgba(255,255,255,.2); font-size:1.5em; border:0px solid rgba(0,0,0,.5); }
.fa-star.checked { color:yellow; }
*/
/* ----- media query------- */

/*
@media(max-width:1024px){
    .productdisplay{
        padding: 0px 8%;
    }
    .productdisplay-img-list{
        gap: 10px;
    }
    .productdisplay-img-list img{
        max-height: 90px;
    }
    .productdisplay-main-img{
        height: 400px;
    }
    
}
@media(max-width:900px){
    .productdisplay{
        gap: 20px;
    }
    .productdisplay-left{
        gap: 10px;
    }
    .productdisplay-img-list{
        gap: 10px;
    }
    .productdisplay-img-list img{
        max-height: 80px;
    }
    .productdisplay-main-img{
        height: 350px;
    }
    
}
@media(max-width:800px){
    .productdisplay{
        flex-wrap: wrap;
    }
    .productdisplay-left{
        width: 100%;
        justify-content: center;
    }
}
@media(max-width:500px){
    .productdisplay-right h1 {
        font-size: 20px;
    }
    .productdisplay-right-prices {
        margin: 15px 0;
        font-size: 18px;
    }
    .productdisplay-right-size h1{
        margin-top: 15px;
    }
    .productdisplay-right-size h1{
        margin: 10px 0;
    }
    .productdisplay-right-size div{
        font-size: 14px;
    }
}
*/