@tailwind base;
@tailwind components;
@tailwind utilities;

*{ margin:0; }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family:'poppins';
  background:#010101; color:#fff;
  background: url('./Components/Assets/Backgrounds/backdrop_1920x1080.jpg') center center fixed;
}

.no-scroll {
  overflow: hidden;
}


#section-offers {
  background-image:url('./Components/Assets/Backgrounds/skulls-strip.jpg');
  background-position: center;
  background-size: cover; 
  border-bottom:1px solid rgba(0,0,0.25);
}


#artisan-divider .flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#artisan-divider  .flex-items {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.awakening-regular {
  /* font-family: 'AWAKENNING', sans-serif; */
  /* font-family: 'Dark Underground', sans-serif; */
  /* font-family: 'Scratched Letters', sans-serif; */
  /* font-family: 'DSIODRER', sans-serif; */
  /* font-family: 'NOVA STAMP', sans-serif; */
  font-family: 'Waukegan Hustle', sans-serif;

}

.road-rage-regular {
  font-family: "Road Rage", sans-serif;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
