.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    background:white;
}
.footer a{
    color:rgba(0,0,0,.75);
    text-decoration:none;
}
.footer a:hover{
    color:#000;
    text-decoration:underline;
}
.footer-links{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    gap: 40px;
    color: rgba(0,0,0,.9);
    font-size: 16px;
}
.footer-links li{
    cursor: pointer;
}
.footer-social-icons{
    display: flex;
    gap: 10px;
}
.footer-icons-container{
    padding: 10px;
    padding-bottom: 6px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
}
.footer-icons-container img{
    width: 25px;
}
.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;

    color: rgba(255,255,255,.9);
    font-size: 16px;
}

.vdiv { color:rgba(255,255,255,.5); }
@media(max-width:800px){
    .footer-links{
        gap: 20px;
        font-size: 14px;
    }
}