.descriptionbox{
    margin: 60px 170px;
    position:relative;
}
.descriptionbox-navigator{
    display: flex;
    position:relative;
}
.descriptionbox-nav-box{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 171px;
    height: 54px;
    border: 1px 1px 0 1px solid #d0d0d0;
    background:#fff;
    color:#333;
    position:relative;
    bottom:-1px;
    border-radius: 10px 10px 0px 0px;
    -webkit-border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
}
.descriptionbox-nav-box.fade{
    background: silver;
    color: #666666;
    box-shadow: inset 0 -50px 36px -28px rgba(0,0,0,.25);
    display:none;
}
.descriptionbox-description{ 
    display: flex;
    flex-direction: column;
    gap: 25px;
    background:#fff;
    color:#333;
    border: 1px solid #D0D0D0;
    padding: 48px;
    padding-bottom: 70px;
    white-space: pre-wrap;

    border-radius: 0px 5px 5px 5px;
    -webkit-border-radius: 0px 5px 5px 5px;
    -moz-border-radius: 0px 5px 5px 5px;
}
@media(max-width:1280px){
    .descriptionbox{
        margin: 60px 60px;
    }
}
@media(max-width:1280px){
    .descriptionbox{
        margin: 60px 20px;
    }
    .descriptionbox-description{
        font-size: 14px;
    }
}