.btn-green{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 75px;
    margin-top: 20px;
    background: rgba(2, 91, 24,.9);
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    cursor: pointer;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);
    transition: background-color 1s;

    align-items: center;  justify-content: center; text-align: center; margin-left: auto;
    margin-right: auto; 
    left: 0; 
    right: 0; 
    max-width:250px;

}
.btn-green:hover{
    font-weight: 550;
    background: rgb(2, 91, 24);
    border:1px solid #013810;
    background: linear-gradient(90deg, #013810, transparent) rgba(2, 91, 24, .9);
}
